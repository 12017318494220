<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Tourism Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Tourim Policy">
          <b-form class="repeater-form" @submit.prevent="repeatPolicy(null)">
            <b-row
              v-for="(Tourism, index) in page.tourismPolicy"
              :key="index"
              ref="tourismrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.tourismPolicy[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTourismPolicy(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Policy Documents">
                  <b-row>
                    <b-col cols="12"> 
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatDocumentList(null, index)"
                      >
                        <b-row
                          v-for="(Tourism, index1) in page.tourismPolicy[index]
                            .policyDocuments"
                          :key="Tourism.id"
                          ref="tourismrow"
                        >
                          <b-col cols="10">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.tourismPolicy[index].policyDocuments[
                                    index1
                                  ].title
                                "
                                placeholder="Policy Document Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeTourismDocument(null, index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-card
                              title="Document Series"
                              style="background: white"
                            >
                              <b-form
                                class="repeater-form"
                                @submit.prevent="
                                  repeatDocument(null, index, index1)
                                "
                              >
                                <b-row
                                  v-for="(Tourism, index2) in page
                                    .tourismPolicy[index].policyDocuments[
                                    index1
                                  ].list"
                                  :id="Tourism.id"
                                  :key="Tourism.id"
                                  ref="tourismrow"
                                >
                                  <b-col cols="5">
                                    <b-form-group label="Title">
                                      <b-form-input
                                        v-model="
                                          page.tourismPolicy[index]
                                            .policyDocuments[index1].list[
                                            index2
                                          ].listTitle
                                        "
                                        placeholder="Series Title"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col cols="5">
                                    <b-form-group label="Download Url">
                                      <b-form-input
                                        v-model="
                                          page.tourismPolicy[index]
                                            .policyDocuments[index1].list[
                                            index2
                                          ].downloadUrl
                                        "
                                        placeholder="Download Url"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="2" md="3" class="mb-50">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="
                                        removeDocumentList(
                                          null,
                                          index,
                                          index1,
                                          index2
                                        )
                                      "
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                      />
                                      <span>Remove</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-col cols="12" class="mt-1"
                                  ><b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="
                                      repeatDocument(null, index, index1)
                                    "
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span>{{
                                      page.tourismPolicy[index].policyDocuments[
                                        index1
                                      ].list.length === 0
                                        ? "Add Series Document"
                                        : "Add New Series Document"
                                    }}</span>
                                  </b-button></b-col
                                >
                              </b-form>
                            </b-card>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatDocumentList(null, index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.tourismPolicy[index].policyDocuments
                                  .length === 0
                                  ? "Add Main Policy Document"
                                  : "Add New Policy Document"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatPolicy(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.tourismPolicy.length === 0
                      ? "Add Main Policy"
                      : "Add New Policy"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Tourism Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translations.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.translations.hi.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Tourim Policy">
          <b-form class="repeater-form" @submit.prevent="repeatPolicy('hi')">
            <b-row
              v-for="(Tourism, index) in page.translations.hi.tourismPolicy"
              :key="Tourism.id"
              ref="tourismrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.hi.tourismPolicy[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTourismPolicy('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Policy Documents">
                  <b-row>
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatDocumentList('hi', index)"
                      >
                        <b-row
                          v-for="(Tourism, index1) in page.translations.hi
                            .tourismPolicy[index].policyDocuments"
                          :key="Tourism.id"
                          ref="tourismrow"
                        >
                          <b-col cols="10">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.translations.hi.tourismPolicy[index]
                                    .policyDocuments[index1].title
                                "
                                placeholder="Policy Document Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeTourismDocument('hi', index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-card
                              title="Document Series"
                              style="background: white"
                            >
                              <b-form
                                class="repeater-form"
                                @submit.prevent="
                                  repeatDocument('hi', index, index1)
                                "
                              >
                                <b-row
                                  v-for="(Tourism, index2) in page.translations
                                    .hi.tourismPolicy[index].policyDocuments[
                                    index1
                                  ].list"
                                  :id="Tourism.id"
                                  :key="Tourism.id"
                                  ref="tourismrow"
                                >
                                  <b-col cols="5">
                                    <b-form-group label="Title">
                                      <b-form-input
                                        v-model="
                                          page.translations.hi.tourismPolicy[
                                            index
                                          ].policyDocuments[index1].list[index2]
                                            .listTitle
                                        "
                                        placeholder="Series Title"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col cols="5">
                                    <b-form-group label="Download Url">
                                      <b-form-input
                                        v-model="
                                          page.translations.hi.tourismPolicy[
                                            index
                                          ].policyDocuments[index1].list[index2]
                                            .downloadUrl
                                        "
                                        placeholder="Download Url"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="2" md="3" class="mb-50">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="
                                        removeDocumentList(
                                          'hi',
                                          index,
                                          index1,
                                          index2
                                        )
                                      "
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                      />
                                      <span>Remove</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-col cols="12" class="mt-1"
                                  ><b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="
                                      repeatDocument('hi', index, index1)
                                    "
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span>{{
                                      page.translations.hi.tourismPolicy[index]
                                        .policyDocuments[index1].list.length ===
                                      0
                                        ? "Add Series Document"
                                        : "Add New Series Document"
                                    }}</span>
                                  </b-button></b-col
                                >
                              </b-form>
                            </b-card>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatDocumentList('hi', index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.translations.hi.tourismPolicy[index]
                                  .policyDocuments.length === 0
                                  ? "Add Main Policy Document"
                                  : "Add New Policy Document"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatPolicy('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.tourismPolicy.length === 0
                      ? "Add Main Policy"
                      : "Add New Policy"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab title="Marathi">
        <b-card title="Tourism Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translations.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.translations.mr.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Tourim Policy">
          <b-form class="repeater-form" @submit.prevent="repeatPolicy('mr')">
            <b-row
              v-for="(Tourism, index) in page.translations.mr.tourismPolicy"
              :key="Tourism.id"
              ref="tourismrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.mr.tourismPolicy[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTourismPolicy('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Policy Documents">
                  <b-row>
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatDocumentList('mr', index)"
                      >
                        <b-row
                          v-for="(Tourism, index1) in page.translations.mr
                            .tourismPolicy[index].policyDocuments"
                          :key="Tourism.id"
                          ref="tourismrow"
                        >
                          <b-col cols="10">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.translations.mr.tourismPolicy[index]
                                    .policyDocuments[index1].title
                                "
                                placeholder="Policy Document Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeTourismDocument('mr', index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-card
                              title="Document Series"
                              style="background: white"
                            >
                              <b-form
                                class="repeater-form"
                                @submit.prevent="
                                  repeatDocument('mr', index, index1)
                                "
                              >
                                <b-row
                                  v-for="(Tourism, index2) in page.translations
                                    .mr.tourismPolicy[index].policyDocuments[
                                    index1
                                  ].list"
                                  :id="Tourism.id"
                                  :key="Tourism.id"
                                  ref="tourismrow"
                                >
                                  <b-col cols="5">
                                    <b-form-group label="Title">
                                      <b-form-input
                                        v-model="
                                          page.translations.mr.tourismPolicy[
                                            index
                                          ].policyDocuments[index1].list[index2]
                                            .listTitle
                                        "
                                        placeholder="Series Title"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col cols="5">
                                    <b-form-group label="Download Url">
                                      <b-form-input
                                        v-model="
                                          page.translations.mr.tourismPolicy[
                                            index
                                          ].policyDocuments[index1].list[index2]
                                            .downloadUrl
                                        "
                                        placeholder="Download Url"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="2" md="3" class="mb-50">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="
                                        removeDocumentList(
                                          'mr',
                                          index,
                                          index1,
                                          index2
                                        )
                                      "
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                      />
                                      <span>Remove</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-col cols="12" class="mt-1"
                                  ><b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="
                                      repeatDocument('mr', index, index1)
                                    "
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span>{{
                                      page.translations.mr.tourismPolicy[index]
                                        .policyDocuments[index1].list.length ===
                                      0
                                        ? "Add Series Document"
                                        : "Add New Series Document"
                                    }}</span>
                                  </b-button></b-col
                                >
                              </b-form>
                            </b-card>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatDocumentList('mr', index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.translations.mr.tourismPolicy[index]
                                  .policyDocuments.length === 0
                                  ? "Add Main Policy Document"
                                  : "Add New Policy Document"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatPolicy('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.tourismPolicy.length === 0
                      ? "Add Main Policy"
                      : "Add New Policy"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      tenders: [],
      page: {
        pageTitle: "",
        description: "",
        tourismPolicy: [],
        translations: {
          hi: {
            pageTitle: "",
            description: "",
            tourismPolicy: [],
          },
          mr: {
            pageTitle: "",
            description: "",
            tourismPolicy: [],
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatDocument(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].tourismPolicy[index].policyDocuments[
          index1
        ].list.push({
          listTitle: "",
         downloadUrl: ""
        });
      } else {
        this.page.tourismPolicy[index].policyDocuments[index1].list.push({
          listTitle: "",
          downloadUrl: ""
        });
      }
    },
    repeatDocumentList(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].tourismPolicy[index].policyDocuments.push({
          title: "",
          list: [],
        });
      } else {
        this.page.tourismPolicy[index].policyDocuments.push({
          title: "",
          list: [],
        });
      }
    },
    removeDocumentList(lang = undefined, index, index1, index2) {
      if (lang) {
        this.page.translations[lang].tourismPolicy[index].policyDocuments[
          index1
        ].list.splice(index2, 1);
      } else {
        this.page.tourismPolicy[index].policyDocuments[index1].list.splice(
          index2,
          1
        );
      }
    },
    removeTourismDocument(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].tourismPolicy[
          index
        ].policyDocuments.splice(index1, 1);
      } else {
        this.page.tourismPolicy[index].policyDocuments.splice(index1, 1);
      }
    },
    repeatPolicy(lang = undefined) {
      if (lang) {
        this.page.translations[lang].tourismPolicy.push({
          title: "",
          policyDocuments: [],
        });
      } else {
        this.page.tourismPolicy.push({
          title: "",
          policyDocuments: [],
        });
      }
    },
    saveDraft() {
      localStorage.setItem("Tourism-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("Tourism-page-data");
      this.page = JSON.parse(page);
    },
    removeTourismPolicy(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].tourismPolicy.splice(index, 1);
      } else {
        this.page.tourismPolicy.splice(index, 1);
      }
    },
    removeDraft() {
      localStorage.removeItem("Tourism-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/tourism/all`)
        .then((response) => {
          this.page = response.data[0];
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/tourism/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log('res', this.page)
           this.makeToast(
                "success",
                "Success",
                `${this.page.pageTitle} has been updated successfully!`
            );
        })
        .catch((error) => {
          console.log(error.response);
           this.makeToast(
                "danger",
                "Error",
                "Error while updating the event!"
              );
        });
    },
  },
  mounted() {
    if (localStorage.getItem("Tourism-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>